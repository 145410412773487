define("bottomline-execution/services/session", ["exports", "ember-simple-auth/services/session", "@ember/service"], function (_exports, _session, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    router: (0, _service.inject)(),
    handleAuthentication: function handleAuthentication() {
      this._super.apply(this, arguments);

      this.get('router').transitionTo('edit-truck');
    },
    handleInvalidation: function handleInvalidation() {
      this._super.apply(this, arguments);

      document.location = '';
    }
  });

  _exports.default = _default;
});