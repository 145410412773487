define("bottomline-execution/models/bx/trip-stop", ["exports", "bottomline-execution/models/trip-stop", "@ember/object", "ember-data", "@ember/service", "ember-uuid", "rsvp", "bottomline-execution/mixins/adapter-options"], function (_exports, _tripStop, _object, _emberData, _service, _emberUuid, _rsvp, _adapterOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: "off" */
  var attr = _emberData.default.attr;

  var _default = _tripStop.default.extend(_adapterOptions.default, {
    store: (0, _service.inject)(),
    adapterActionMapping: {
      L: 'confirm-load',
      U: 'confirm-unload-transactions'
    },
    loadUUID: attr('string', {
      defaultValue: function defaultValue(tripStop) {
        return tripStop.generateUUID();
      }
    }),
    generateUUID: function generateUUID() {
      return (0, _emberUuid.v4)();
    },
    save: function save() {
      if (this.get('isVolatileRemoveableRecord')) {
        return _rsvp.Promise.resolve();
      }

      return this._super.apply(this, arguments);
    },
    plannedMaterials: (0, _object.computed)('trip_stop_truck_storages.@each.MaterialID', function () {
      var plannedMaterialIds = this.get('trip_stop_truck_storages').mapBy('MaterialID');
      return this.get('store').peekAll('material').filter(function (m) {
        return plannedMaterialIds.includes(m.id);
      });
    }),
    start: function start() {
      this._super.apply(this, arguments);

      return this.save(this.adapterAction('start'));
    },
    cancel: function cancel() {
      this._super.apply(this, arguments);

      if (this.get('state') !== 'finished') {
        this.setProperties({
          ConfirmedArrivalDT: null,
          ConfirmedSequenceNb: null,
          state: 'planned'
        });
        this.save(this.adapterAction('cancel'));
      }
    },
    _persistChanges: function _persistChanges() {
      var _this = this;

      var finishTripStop = this._transitionToFinished();

      return this.save(this._adapterAction()).then(function () {
        _this.resetChangedAttributes('trip_stop_storages');

        _this.resetChangedAttributes('trip_stop_truck_storages');

        if (finishTripStop) {
          return _this.save(_this.adapterAction('finish'));
        }
      }).catch(function (error) {
        console.error('Error saving trip stop:', error.message, error.payload);

        _this.cancel();
      });
    },
    _transitionToFinished: function _transitionToFinished() {
      return this.changedAttributes().state && this.changedAttributes().state[1] == 'finished';
    },
    _adapterAction: function _adapterAction() {
      var action = this.get('adapterActionMapping')[this.get('ActivityCode')];
      return this.adapterAction(action);
    },
    resetChangedAttributes: function resetChangedAttributes(association) {
      this.get(association).invoke('save');
    }
  });

  _exports.default = _default;
});