define("bottomline-execution/adapters/bx/trip-stop", ["exports", "bottomline-execution/adapters/bx/base-adapter"], function (_exports, _baseAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdapter.default.extend({
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var baseUrlForUpdateRecord = this._super(id, modelName, snapshot);

      var tripId = snapshot.attr('TripID');
      var stopId = snapshot.id;
      var action = snapshot.adapterAction();
      return "".concat(baseUrlForUpdateRecord, "/trips/").concat(tripId, "/stops/").concat(stopId, "/").concat(action);
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      if (this.offlineQueueAdd(snapshot)) return Promise.resolve();
      var timeAttributeMapping = {
        'start': 'ConfirmedArrivalDT',
        'confirm-load': 'ConfirmedDepartureDT',
        'confirm-unload-transactions': 'ConfirmedDepartureDT'
      };
      var timestamp = this.getTimestampFrom(snapshot, timeAttributeMapping);
      var timestampHeader = this.timestampHeader(timestamp);
      var url = this.urlForUpdateRecord(snapshot.id, snapshot.modelName, snapshot);
      return this.get('ajaxService').post(url, {
        headers: timestampHeader,
        data: snapshot.serialize()
      });
    }
  });

  _exports.default = _default;
});