define("bottomline-execution/controllers/application", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/controller", "@ember/object", "@ember/service", "@ember/object/evented", "@ember/utils", "bottomline-execution/utils/multi-click-handler", "bottomline-execution/utils/url-utils", "bottomline-execution/mixins/version"], function (_exports, _computed, _runloop, _controller, _object, _service, _evented, _utils, _multiClickHandler, _urlUtils, _version) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_version.default, {
    adrReportController: (0, _controller.inject)('shifts.adr-report'),
    queryParams: ['editTruck'],
    session: (0, _service.inject)(),
    locationService: (0, _service.inject)('location'),
    androidLogging: (0, _service.inject)(),
    offlineSyncerService: (0, _service.inject)('offline-syncer'),
    i18n: (0, _service.inject)(),
    androidPermissions: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    applicationReload: (0, _service.inject)(),
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    isGeneratingAdrReport: false,
    _setup: (0, _evented.on)('init', function () {
      // Initialize services
      this.get('applicationReload');
      this.get('locationService');
      this.get('offlineSyncerService');
      this.get('androidLogging');
      this.get('internetConnection');
      window.addEventListener('resize', this.get('windowResizeHandler').bind(this));
    }),
    currentTruckChanged: (0, _object.observer)('model.currentTruck', function () {
      this.get('locationService').set('truckId', this.get('model').get('currentTruck'));
    }),
    appState: (0, _computed.alias)('model'),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    currentPath: (0, _computed.alias)('router.currentRouteName'),
    currentURL: (0, _computed.alias)('router.currentURL'),
    isShiftIndex: (0, _computed.equal)('currentPath', 'shifts.index'),
    withinShift: (0, _object.computed)('currentURL', function () {
      return this.get('currentURL').indexOf('trips') > -1;
    }),
    emptyShiftsIndex: (0, _object.computed)('currentPath', function () {
      if (this.get('currentPath') === 'shifts.index') {
        return (0, _utils.isEmpty)(this.get('store').peekAll('shift'));
      }

      return false;
    }),
    showADRReport: (0, _object.computed)('withinShift', 'emptyShiftsIndex', function () {
      if (this.get('withinShift') || this.get('emptyShiftsIndex')) return true;
      return false;
    }),
    enableADRReport: (0, _object.computed)('emptyShiftsIndex', 'canGenerateADRReport', function () {
      if (this.get('emptyShiftsIndex') || this.get('canGenerateADRReport')) return true;
      return false;
    }),
    actionEnabled: (0, _object.computed)('online', 'blockExit', function () {
      return this.get('online') && !this.get('blockExit');
    }),
    blockExit: (0, _object.computed)('currentURL', function () {
      var _this = this;

      return ['trips', 'adr', 'reorder-trip-stops'].any(function (subPath) {
        return _this.get('currentURL').indexOf(subPath) > -1;
      });
    }),
    online: (0, _computed.alias)('appState.online'),
    canGenerateADRReport: (0, _object.computed)('appState.online', 'appState.anyShiftStarted', 'isGeneratingAdrReport', function () {
      return this.get('appState.online') && this.get('appState.anyShiftStarted') && !this.get('isGeneratingAdrReport');
    }),
    backendEnvironment: (0, _object.computed)('appState.alias', 'appState.display_name', function () {
      var url = this.get('appState.domain');
      var alias = this.get('appState.alias');
      var displayName = this.get('appState.display_name');

      var domain = _urlUtils.default.extractDomainName(url);

      if (_urlUtils.default.isIpAddress(domain)) {
        // If the domain is an IP address, show it as-is
        return domain;
      } else {
        if ((0, _utils.isPresent)(displayName)) return displayName;
        if ((0, _utils.isPresent)(alias)) return alias;
        return '';
      }
    }),
    truckSelected: (0, _object.computed)('appState.currentTruck', function () {
      return (0, _utils.isPresent)(this.get('appState').getTruck());
    }),
    currentTruck: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('appState').getTruck().get('identifier');
    }),
    doesTrailerExist: (0, _object.computed)('appState.currentTrailer', function () {
      return (0, _utils.isPresent)(this.get('appState').getTrailer());
    }),
    currentTrailer: (0, _object.computed)('appState.currentTrailer', function () {
      return this.get('appState').getTrailer().get('identifier');
    }),
    canOpenShifts: (0, _object.computed)('appState.currentTruck', 'online', function () {
      return (0, _utils.isPresent)(this.get('appState.currentTruck')) && this.get('online');
    }),
    smallScreenSize: (0, _computed.alias)('truckSettings.small_screen_ui'),
    showLocationPin: (0, _computed.not)('truckSettings.location_tracking_disabled'),
    windowResizeHandler: function windowResizeHandler() {
      this.get('appState').notifyPropertyChange('deviceOrientation');
    },
    actions: {
      back: function back() {
        window.history.back();
      },
      navigate: function navigate(path) {
        if (/^trip-stops.*/.test(path) || 'trips.index' == path || 'shifts.index' == path) {
          alert(this.get('i18n').t('back_button_not_supported'));
        }
      },
      adrReport: function adrReport() {
        var _this2 = this;

        var useDeprecatedSource = this.get('truckSettings.deprecated_source_adr_report');
        var adrReportController = this.get('adrReportController');

        if (useDeprecatedSource) {
          if (useDeprecatedSource == 'jsreport' && adrReportController.canGenerateADRReport()) {
            this.set('isGeneratingAdrReport', true);
            adrReportController.generateADRReport().then(function () {
              return _this2.set('isGeneratingAdrReport', false);
            });
          } else if (useDeprecatedSource == 'on-screen') {
            // open the page in the app with ADR information
            this.router.transitionTo('shifts.adr-report', this.get('appState.selectedShiftId'));
          }
        } else {
          var truckId = this.get('appState.currentTruck');

          var flagOpeningAdrReport = function flagOpeningAdrReport(opening) {
            _this2.set('isGeneratingAdrReport', opening);
          };

          var errorOpeningDocument = function errorOpeningDocument(_error) {
            _this2.get('f7').alert(_this2.get('i18n').t('truck.documents.notfound'), '');
          };

          var pdfDocument = this.get('store').createRecord('pdf-document', {
            documentType: 'adrReport',
            modelId: truckId
          });
          pdfDocument.open({
            signalOpening: flagOpeningAdrReport,
            showWarning: errorOpeningDocument
          });
        }
      },
      showTruckSettings: function showTruckSettings() {
        var _this3 = this;

        if (!this.get('isShiftIndex')) return;

        if ((0, _utils.isEmpty)(this.get('truckSettingsMultiClickHandler'))) {
          this.set('truckSettingsMultiClickHandler', _multiClickHandler.default.create({
            runCallback: function runCallback() {
              _this3.router.transitionTo('truck-settings');
            }
          }));
        }

        this.get('truckSettingsMultiClickHandler').registerClick();
      },
      sync: function sync() {
        this.get('f7').confirm(this.get('i18n').t('sync.warning'), this.get('i18n').t('sync.title'), (0, _runloop.bind)(this, function () {
          window.location.reload();
        }));
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });

  _exports.default = _default;
});