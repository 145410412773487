define("bottomline-execution/mixins/picture-browser", ["exports", "@ember/runloop", "jquery", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/service", "@ember/utils", "bottomline-execution/config/environment", "bottomline-execution/utils/test-image-util", "bottomline-execution/utils/promise", "bottomline-execution/mixins/app-state"], function (_exports, _runloop, _jquery, _object, _computed, _mixin, _service, _utils, _environment, _testImageUtil, _promise, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_appState.default, {
    pictureSaver: (0, _service.inject)(),
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    retry: 0,
    photosStorageID: null,
    pictureBrowserElement: null,
    onPictureSuccess: function onPictureSuccess(imageData) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'png';
      var tripStop = this.get('model');

      if ((0, _utils.isEmpty)(imageData)) {
        this.get('toast').error(this.get('i18n').t('no_image_data.camera'));
        return;
      }

      var StorageID = this.get('photosStorageID');
      var tripStopStorageID = tripStop.get('hasSeningMeter') && StorageID ? StorageID : -1;
      var pictureData = {
        imageData: imageData,
        TableKey: tripStop.id,
        TableName: 'TripStop',
        StorageID: tripStopStorageID,
        Description: format,
        PictureType: 'picture',
        BE_PictureGUID: tripStop.createGuid()
      };
      var picture = tripStop.get('pictures').createRecord(pictureData);
      this.get('pictureSaver').save(picture);
    },
    isAtx: (0, _object.computed)(function () {
      return _environment.default.APP.target === 'atx' && typeof plugins !== 'undefined';
    }),
    onPictureFail: function onPictureFail(message) {
      alert(message);
    },
    attemptToShowBrowser: function attemptToShowBrowser(pictures) {
      var _this = this;

      if ((0, _utils.isPresent)(this.get('pictureBrowserElement'))) return;
      this.get('f7').hideIndicator();

      if (pictures) {
        var parameters = {
          swipeToClose: false,
          minZoom: 1,
          exposition: false,
          maxZoom: 1,
          onClose: function onClose() {
            _this.set('pictureBrowserElement', null);

            (0, _jquery.default)(".navbar").show();
          },
          photos: pictures,
          type: 'standalone',
          navbarTemplate: '<div class="navbar"> <div class="navbar-inner"> <div class="left sliding"> <a href="javascript:void(0);" class="close-popup photo-browser-close-link button button-fill bl-components__button picture-browser__close-button"><span>Close</span> </a> </div> <div class="center sliding"> <span class="photo-browser-current"></span> <span class="photo-browser-of">of</span> <span class="photo-browser-total"></span> </div> <div class="right"></div> </div> </div>',
          toolbarTemplate: '<div class="toolbar tabbar"><div class="toolbar-inner"><a href="#" class="photo-browser-prev button button-fill bl-components__button picture-browser__navigation-button picture-browser__navigation-button--prev"><i class="mdi mdi-navigate-before"></i></a><a href="#" class="photo-browser-next button button-fill bl-components__button picture-browser__navigation-button picture-browser__navigation-button--next"><i class="mdi mdi-navigate-next"></i></a></div></div>',
          swiper: {
            lazy: {
              enabled: true
            }
          }
        };
        var pictureBrowserElement = this.get('f7').photoBrowser(parameters);
        this.set('pictureBrowserElement', pictureBrowserElement);
        pictureBrowserElement.open();
        return (0, _promise.timeout)(250);
      }
    },
    actions: {
      capture: function capture(tripStop, StorageID) {
        if (StorageID) {
          this.set('photosStorageID', Number(StorageID));
        } else {
          this.set('photosStorageID', null);
        }

        if (!navigator.camera) {
          if (this.get('truckSettings.webcam_enabled_for_webversion')) {
            this.router.transitionTo('trip-stops.camera', tripStop.id);
          } else {
            alert('No camera found, creating test image');
            var picture = tripStop.get('pictures').createRecord({
              imageData: (0, _testImageUtil.testImage)(),
              TableKey: tripStop.id,
              TableName: 'TripStop',
              Description: 'png',
              PictureType: 'picture',
              BE_PictureGUID: tripStop.createGuid()
            });
            this.get('pictureSaver').save(picture);
          }
        } else {
          navigator.camera.getPicture((0, _runloop.bind)(this, this.onPictureSuccess), (0, _runloop.bind)(this, this.onPictureFail), {
            quality: 99,
            destinationType: Camera.DestinationType.DATA_URL,
            sourceType: Camera.PictureSourceType.CAMERA,
            targetWidth: 1024,
            targetHeight: 800,
            saveToPhotoAlbum: false,
            encodingType: Camera.EncodingType.PNG
          });
        }
      },
      viewGallery: function viewGallery(item) {
        this.get('f7').showIndicator();
        var picturesForItem = item.store.peekAll('picture').filterBy('TableKey', item.id);
        return this.attemptToShowBrowser(this._mapForPictureBrowser(picturesForItem));
      }
    },
    _mapForPictureBrowser: function _mapForPictureBrowser(pictures) {
      if (this.get('isAtx')) {
        return this._mapPicturesForAtx(pictures);
      }

      var urlType = this.get('appState.online') ? 'fullUrl' : 'localUrl';
      return pictures.map(function (p) {
        return {
          url: p.get(urlType)
        };
      });
    },
    _mapPicturesForAtx: function _mapPicturesForAtx(pictures) {
      var _this2 = this;

      return pictures.map(function (p) {
        return {
          caption: _this2.get('i18n').t('preview_not_available_on_atx'),
          url: p.get('imageUnavailableUrl')
        };
      });
    }
  });

  _exports.default = _default;
});