define("bottomline-execution/models/picture", ["exports", "@ember/object", "@ember/utils", "@ember/service", "ember-data", "bottomline-execution/mixins/app-state"], function (_exports, _object, _utils, _service, _emberData, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend(_appState.default, {
    assetMap: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    url: attr('string'),
    imageData: attr('string'),
    TableName: attr('string'),
    TableKey: attr('string'),
    Description: attr('string'),
    PictureType: attr('string'),
    StorageID: attr('number'),
    PictureAsJSON: attr('string'),
    BE_PictureGUID: attr('string'),
    tripStop: belongsTo('trip-stop'),
    fullUrl: (0, _object.computed)('imageData', 'url', function () {
      if ((0, _utils.isPresent)(this.get('imageData'))) {
        return this.get('imageDataUrl');
      }

      if ((0, _utils.isPresent)(this.get('url'))) {
        return "".concat(this.get('appState.domain')).concat(this.get('url'));
      }

      return this.get('imageUnavailableUrl');
    }),
    localUrl: (0, _object.computed)('imageData', function () {
      if ((0, _utils.isPresent)(this.get('imageData'))) {
        return this.get('imageDataUrl');
      }

      return this.get('imageUnavailableUrl');
    }),
    imageDataUrl: (0, _object.computed)('imageData', function () {
      return "data:image/png;base64,".concat(this.get('imageData'));
    }),
    imageUnavailableUrl: (0, _object.computed)(function () {
      return this.get('assetMap').resolve('assets/no_picture.png');
    })
  });

  _exports.default = _default;
});